import React, { useState, useEffect } from 'react'
import { peopleVideos } from '../../constants/peopleVideos.js';
import Button from '../../../components/common/Button/Button.js';
import "./Filter.scss";
import Card from '../../../components/ui/Card/Card.js';

const Filter = () => {
  const [categoryFilter, setCategoryFilter] = useState("");
  const [areaFilter, setAreaFilter] = useState("");
  const [showVideos, setShowVideos] = useState(peopleVideos);
  const [activeVideo, setActiveVideo] = useState(false)
  const [src, setSrc] = useState("");

  const handleChange = (event) => {
    let video = "https://lardados-e-pedados.s3.sa-east-1.amazonaws.com/" + event
    try {
      setSrc(video);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    
    if (categoryFilter === "question"){
      setAreaFilter("")
    }

    const videos = peopleVideos.filter((video) => {
      const hasArea = areaFilter === "" || video.rangeAge === areaFilter;
      const hasCategory = categoryFilter === "" || video.gender === categoryFilter;
      return hasCategory && hasArea && video;
    })
    setShowVideos(videos)
  }, [categoryFilter, areaFilter])

  const toggleFilter = (filter, updateState, value) => {
    if (filter !== value) {
      updateState(value)
    }
    else {
      updateState("")
    }
  }
  
  return (
    <main className='Filter'>
      <div className='filters'>
        <div className='filter-group'>
          <div className='filter-group-buttons'>
            <Button onClick={() => toggleFilter(categoryFilter, setCategoryFilter, "man")} active={categoryFilter === "man"}>Homem</Button>
            <Button onClick={() => toggleFilter(categoryFilter, setCategoryFilter, "woman")} active={categoryFilter === "woman"}>Mulher</Button>
            <Button onClick={() => toggleFilter(categoryFilter, setCategoryFilter, "question")} active={categoryFilter === "question"}>perguntas</Button>
          </div>
        </div>
        <div className='filter-group'>
          {categoryFilter !== "question" && <div className='filter-group-buttons'>
            <Button onClick={() => toggleFilter(areaFilter, setAreaFilter, "18")} active={areaFilter === "18"}>18 À 29</Button>
            <Button onClick={() => toggleFilter(areaFilter, setAreaFilter, "30")} active={areaFilter === "30"}>30 À 39</Button>
            <Button onClick={() => toggleFilter(areaFilter, setAreaFilter, "40")} active={areaFilter === "40"}>40+</Button>
          </div>}
        </div>
      </div>
      <section>
        {showVideos?.map((info, index) =>
          <Card onClick={() => {
            setActiveVideo(true)
            handleChange(info?.video)
          }} key={index} info={info} />
        )}
      </section>
      {activeVideo &&
        <div className='frame-video'>
          <div className='container-video'>
            <video autoPlay type="video/webm" src={src} controls width="100%">
            </video>
            <div className='container-button'>
              <Button onClick={() => setActiveVideo(false)}>Fechar</Button>
            </div>
          </div>
        </div>}
    </main>
  )
}

export default Filter
