import React from 'react';
import './Button.scss';

const Button = ({ onClick, active, children, className, simple }) => {
  return (
    <button className={`Button ${active ? "active" : ""} ${className ? className : ""} ${simple ?  "simple" : ""}`} onClick={onClick}>
      { children }
    </button>
    )
  }
  
export default Button;
