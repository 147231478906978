import React, { useState } from 'react';
import Button from '../../../components/common/Button/Button.js';
import CardProject from '../../../components/ui/CardProject/CardProject.js';
import { projectsList } from '../../constants/projectsList.js';
import "./Projects.scss";

const Projects = () => {
  const [activeVideo, setActiveVideo] = useState(false)
  const [src, setSrc] = useState("");

  const handleChange = (event) => {
    let video = "https://lardados-e-pedados.s3.sa-east-1.amazonaws.com/" + event.link
    try {
      setSrc(video);
      setActiveVideo(true)
    } catch (error) {
      console.error(error);
    }
  };

  const handleRedirect = (info) => {
    window.open(info.link, '_blank');
  }

  return (
    <main className='Projects'>
      <section>
        {projectsList?.map((info, index) =>
          <CardProject onClick={() => {
            if (info.openLink === "VIDEO") {
              handleChange(info)
            } else {
              handleRedirect(info)
            }
          }} key={index} info={info} />
        )}
      </section>
      {activeVideo &&
        <div className='frame-video'>
          <div className='container-video'>
            <video autoPlay type="video/webm" src={src} controls width="100%">
            </video>
            <div className='container-button'>
              <Button onClick={() => setActiveVideo(false)}>Fechar</Button>
            </div>
          </div>
        </div>}
    </main>
  )
}

export default Projects
