import React, {useState} from 'react'
import "./Apresentation.scss";

const Apresentation = () => {
  const [mutedVideo, setMutedVideo] = useState(true)
  return (
    <div onClick={() => setMutedVideo(!mutedVideo)} className='Apresentation'>
      <video autoPlay loop disablePictureInPicture muted={mutedVideo} width='140vw'>
        <source src="https://lardados-e-pedados.s3.sa-east-1.amazonaws.com/lardadosepedados.webm"
         type="video/webm"/>
      </video>
    </div>
  )
}

export default Apresentation
