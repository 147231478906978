import React from 'react';
import { replaceWithLineBreaks } from '../../../utils/formating';
import "./CardProject.scss";

const CardProject = ({ info, onClick }) => {
  const renderCardProject = () => {
    if (info?.marketplace) {
      return (
        <div className='CardProject marketplace'>
          <p dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(info.name) }}></p>
        </div>
      )
    }

    if (info?.type) {
      return (
        <div className='CardProject home'>
          <p dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(info.name) }}></p>
        </div>
      )
    }

    if (info?.gender === "question") {
      return (
        <div onClick={onClick} className='CardProject question'>
          <p>{info.name}  #<span>{info?.number}</span></p>
          <p dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(info.question) }}></p>
          <button className='button-question'>CLIQUE AQUI PARA VER</button>
        </div>
      )
    } else {
      const styles = {
        backgroundImage: `url(${info?.image})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      };

      return (
        <div onClick={onClick} className='CardProject' style={styles}>
          <div className='container-info'>
            <p dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(info.name) }}></p>
            <p>{info.age}</p>
          </div>
        </div>
      )
    }
  }

  return (
    renderCardProject()
  )
}

export default CardProject
