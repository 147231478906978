export const peopleVideos = [
  {
    name: "VOCÊ \n COSTUMA \n BEBER \n CERVEJA \n EM CASA?",
    number: '1',
    gender: "question",
    video: "pergunta-1.webm"
  },
  {
    name: "GUILHERME \n ESTEVÃO,",
    age: "37",
    rangeAge: "30",
    image: require("../../assets/images/peoples/guilherme_estevao.png"),
    gender: "man",
    video: "guilherme-estevao.webm"
  },
  {
    name: "EM QUE \n OCASIÕES \n VOCÊ \n COSTUMA \n BEBER \n CERVEJA \n EM CASA?",
    number: '2',
    gender: "question",
    video: "pergunta-2.webm"
  },
  {
    name: "EM<span>CASA</span>",
    type: true,
  },
  {
    name: "GIOVANA \n CARREIRA,",
    age: "23",
    rangeAge: "18",
    image: require("../../assets/images/peoples/giovana_carreira.png"),
    gender: "woman",
    video: "giovana-carreira.webm"
  },
  {
    name: "VOCÊ \n PREFERE \n BEBER \n CERVEJA \n EM GARRAFAS, \n LATA \n OU COPOS?",
    number: '3',
    gender: "question",
    video: "pergunta-3.webm"
  },
  {
    name: "JULIANA \n GELBAUM,",
    age: "37",
    rangeAge: "30",
    image: require("../../assets/images/peoples/juliana_guelbaum.png"),
    gender: "woman",
    video: "juliana-gelbaum.webm"
  },
  {
    name: "VOCÊ \n COSTUMA \n CONSUMIR \n CERVEJA \n ENQUANTO \n ASSISTE TV \n OU REALIZA \n OUTRA \n ATIVIDADE?",
    number: '4',
    gender: "question",
    video: "pergunta-4.webm"
  },
  {
    name: "HÁ ALGUMA \n SITUAÇÃO \n EM QUE VOCÊ \n EVITE BEBER \n CERVEJA \n EM CASA?",
    number: '5',
    gender: "question",
    video: "pergunta-5.webm"
  },
  {
    name: "CAMILLA \n MAGELA,",
    age: "25",
    rangeAge: "18",
    image: require("../../assets/images/peoples/camilla_magela.png"),
    gender: "woman",
    video: "camilla_magela.webm"
  },
  {
    name: "VOCÊ \n COSTUMA \n OFERECER \n CERVEJA \n AOS SEUS \n CONVIDADOS \n QUANDO ELES \n VISITAM \n SUA CASA?",
    number: '6',
    gender: "question",
    video: "pergunta-6.webm"
  },
  {
    name: "ALEXSANDRA \n LUCENA,",
    age: "32",
    rangeAge: "30",
    image: require("../../assets/images/peoples/alexsandra_lucena.png"),
    gender: "woman",
    video: "alexsandra_lucena.webm"
  },
  {
    name: "NO<span>MERCADO</span>",
    marketplace: true,
  },
  {
    name: "QUANDO TEM \n CRIANÇAS \n EM CASA, \n VOCÊ MODIFICA \n O SEU CONSUMO \n DE CERVEJA?",
    number: '7',
    gender: "question",
    video: "pergunta-7.webm"
  },
  {
    name: "ANA \n BEATRIZ,",
    age: "27",
    rangeAge: "18",
    image: require("../../assets/images/peoples/ana_beatriz.png"),
    gender: "woman",
    video: "ana-beatriz.webm"
  },
  {
    name: "VOCÊ COSTUMA \n ACOMPANHAR \n SUAS CERVEJAS \n COM ALGUM \n TIPO DE PETISCO \n OU COMIDA?",
    number: '8',
    gender: "question",
    video: "pergunta-8.webm"
  },
  {
    name: "GABRIEL \n ROGER,",
    age: "27",
    rangeAge: "18",
    image: require("../../assets/images/peoples/gabriel_roger.png"),
    gender: "man",
    video: "gabriel_roger.webm"
  },
  {
    name: "VOCÊ COSTUMA \n COMPRAR \n CERVEJA \n NO \n SUPERMERCADO?",
    number: '9',
    gender: "question",
    video: "pergunta-9.webm"
  },
  {
    name: "CAROLINA \n THOMAZ,",
    age: "24",
    rangeAge: "18",
    image: require("../../assets/images/peoples/carolina_thomaz.png"),
    gender: "woman",
    video: "carolina_thomaz.webm"
  },
  {
    name: "MAYARA \n MELO,",
    age: "29",
    rangeAge: "18",
    image: require("../../assets/images/peoples/mayara_melo.png"),
    gender: "woman",
    video: "mayara_melo.webm"
  },
  {
    name: "QUAIS FATORES \n SÃO MAIS \n IMPORTANTES \n PARA VOCÊ \n ESCOLHER \n UMA CERVEJA?",
    number: '10',
    gender: "question",
    video: "pergunta-10.webm"
  },
  {
    name: "DIANDRA \n ANDRADE,",
    age: "32",
    rangeAge: "30",
    image: require("../../assets/images/peoples/diandra_andrade.png"),
    gender: "woman",
    video: "diandra-andrade.webm"
  },
  {
    name: "AS INFORMAÇÕES \n PRESENTES \n NO RÓTULO \n OU NA \n EMBALAGEM \n INFLUENCIAM \n SUA DECISÃO \n DE COMPRA?",
    number: '11',
    gender: "question",
    video: "pergunta-11.webm"
  },
  {
    name: "COSTUMA \n ESCOLHER \n AS MESMAS \n MARCAS OU \n GOSTA DE \n EXPERIMENTAR \n NOVAS MARCAS?",
    number: '12',
    gender: "question",
    video: "pergunta-12.webm"
  },
  {
    name: "RAFAEL \n TIERNO,",
    age: "25",
    rangeAge: "18",
    image: require("../../assets/images/peoples/rafael_tierno.png"),
    gender: "man",
    video: "rafael-tierno.webm"
  },
  {
    name: "COSTUMA \n COMPRAR \n CERVEJA EM \n GRANDES \n QUANTIDADES \n OU APENAS \n ALGUMAS \n UNIDADES?",
    number: '13',
    gender: "question",
    video: "pergunta-13.webm"
  },
  {
    name: "NICHOLAS \n ZACARIAS,",
    age: "23",
    rangeAge: "18",
    image: require("../../assets/images/peoples/nicolas_zacarias.png"),
    gender: "man",
    video: "nicolas_zacarias.webm"
  },
  {
    name: "ANTÔNIO \n CARLOS,",
    age: "63",
    rangeAge: "40",
    image: require("../../assets/images/peoples/antonio_carlos.png"),
    gender: "man",
    video: "antonio-carlos.webm"
  },
  {
    name: "COSTUMA \n  COMPRAR \n  OUTROS \n  PRODUTOS \n  JUNTO?",
    number: '14',
    gender: "question",
    video: "pergunta-14.webm"
  },
  {
    name: "ROBERTO \n ACHUTTI,",
    age: "61",
    rangeAge: "40",
    image: require("../../assets/images/peoples/roberto_achutti.png"),
    gender: "man",
    video: "roberto_achutti.webm"
  },
  {
    name: "VOCÊ JÁ \n EXPERIMENTOU \n UMA CERVEJA \n APENAS PORQUE \n VIU UMA \n PROPAGANDA \n DELA?",
    number: '15',
    gender: "question",
    video: "pergunta-15.webm"
  },
  {
    name: "ADRIANA \n AMORIM,",
    age: "53",
    rangeAge: "40",
    image: require("../../assets/images/peoples/adriana_amorim.png"),
    gender: "woman",
    video: "adriana-amorim.webm"
  },
  {
    name: "KATIA \n CAMPANILE,",
    age: "51",
    rangeAge: "40",
    image: require("../../assets/images/peoples/katia_campanile.png"),
    gender: "woman",
    video: "katia-campanile.webm"
  },
  {
    name: "QUE TIPO \n DE PROPAGANDA \n DE CERVEJA \n CHAMA MAIS \n SUA ATENÇÃO?",
    number: '16',
    gender: "question",
    video: "pergunta-16.webm"
  },
  {
    name: "LILIAN \n LIMA,",
    age: "44",
    rangeAge: "40",
    image: require("../../assets/images/peoples/lilian_lima.png"),
    gender: "woman",
    video: "lilian-lima.webm"
  },
  {
    name: "YARA \n LEITE,",
    age: "65",
    rangeAge: "40",
    image: require("../../assets/images/peoples/yara_leite.png"),
    gender: "woman",
    video: "yara-leite.webm"
  },
]
