import React from 'react'
import "./Card.scss";
import { replaceWithLineBreaks } from '../../../utils/formating';

const Card = ({ info, onClick }) => {
  const renderCard = () => {
    if (info?.marketplace) {
      return (
        <div className='Card marketplace'>
          <p dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(info.name) }}></p>
        </div>
      )
    }

    if (info?.type) {
      return (
        <div className='Card home'>
          <p dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(info.name) }}></p>
        </div>
      )
    }

    if (info?.gender === "question") {
      return (
        <div onClick={onClick} className='Card question'>
          <p>#<span>{info?.number}</span></p>
          <p dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(info.name) }}></p>
        </div>
      )
    } else {
      const styles = {
          backgroundImage: `url(${info?.image})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
      };

      return (
        <div onClick={onClick} className='Card' style={styles}>
          <div className='container-info'>
            <p dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(info.name) }}></p>
            <p>{info.age}</p>
          </div>
        </div>
      )
    }
  }

  return (
    renderCard()
  )
}

export default Card
