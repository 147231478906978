import Apresentation from "../assets/pages/Apresentation/Apresentation";
import Filter from "../assets/pages/Filter/Filter";
import Home from "../assets/pages/Home/Home";
import Projects from "../assets/pages/Projects/Projects";
import "./App.scss";

function App() {
  return (
    <div className="App scroll-snap-container">
      <Home />
      <Apresentation />
      <Filter />
      <Projects />
    </div>
  );
}

export default App;
