import React from 'react'
import "./Home.scss";

const Home = () => {
  return (
    <div className='Home'>
      <div className='container-home'>
        <div>
          <p>HEINEKEN</p>
          <p>E_MASTERTECH</p>
          <p>APRESENTAM</p>
        </div>
        <h1>LAR<span className='italic'>DADOS</span> <br/>E <strong>PE<span>DADOS</span></strong></h1>
      </div>
    </div>
  )
}

export default Home
